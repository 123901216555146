<template>
  <div>
    <div class="text-center">
      <img src="@/assets/images/giftbox/gb1.jpg" width="100%" alt="">
    </div>

    <div class="step-content">
      <div class="title">3 bước đơn giản sử dụng Scan&#38;Go</div>
      <ul class="step-list">
        <li>
          <img src="@/assets/images/giftbox/gb_step1.png" alt="">
          <div class="text">
            <strong>BƯỚC 1</strong>
            <p>Quét QR Code để<br/>Tải App VinID</p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/giftbox/gb_step2.png" alt="">
          <div class="text">
            <strong>BƯỚC 2</strong>
            <p>Mở App, chọn Scan&#38;Go để quét mã QR<br/>hoặc mã vạch sản phẩm bạn muốn mua </p>
          </div>
        </li>
        <li>
          <img src="@/assets/images/giftbox/gb_step3.png" alt="">
          <div class="text">
            <strong>BƯỚC 3</strong>
            <p>Thanh toán trên App và<br/>nhận hàng tại nhà</p>
          </div>
        </li>
      </ul>
    </div>

    <div class="giftcard">
      <div class="title">Quà gặp mặt tháng này</div>
      <p>TẶNG BỘ VOUCHER 300k<br/>CHO ĐƠN HÀNG 300K ĐẦU TIÊN GỒM:</p>
      <ul class="gc-list">
        <li>
          <a target="_blank" class="item">
            <img src="@/assets/images/giftbox/gb2.jpg" alt=""/>
            <div class="text">Tặng 02 voucher 50.000 giảm trực tiếp cho 2 hóa đơn Scan&#38;Go tiếp theo</div>
          </a>
        </li>
        <li>
          <a target="_blank" class="item">
            <img src="@/assets/images/giftbox/gb3.jpg" alt=""/>
            <div class="text text2">
              <strong>Tặng voucher 50.000</strong>
              <span>Grabfood &#38; 50.000 Grabexpress</span>
            </div>
          </a>
        </li>
        <li>
          <a target="_blank" class="item">
            <img src="@/assets/images/giftbox/gb4.jpg" alt=""/>
            <div class="text">Quét Scan&#38;Go thanh toán Napas tặng 100.000</div>
          </a>
        </li>
      </ul>

      <div class="see-detail">Xem chi tiết <a href="https://bit.ly/SnGThele" target="_blank">tại đây</a></div>
    </div>

    <div class="button-download" id="sticky">
      <a class="btn-download cta-sticky" href="https://vinid.page.link/?link=http://qr.id.vin/C.5.SECRET01.CAT01&apn=com.vingroup.vinid&isi=1215495998&ibi=com.vingroup.VinIDApp" target="_blank">Tải app VinID ngay</a>
    </div>
  </div>
</template>

<script>
export default {
  name: "giftbox"
};
</script>

<style lang="scss" scoped>
.button-download {
  text-align: center;
  margin-bottom: 50px;

  &#sticky {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    margin-bottom: 0;
    height: 80px;
    background-color: #fff;
    box-shadow: 0 -4px 15px 4px rgba(0, 0, 0, 0.08);
    padding-top: 16px;
    z-index: 30;
  }
}
.step-content {
  background: #f7d131;
  padding: 28px 24px 36px;

  .title {
    background: #fff;
    border: 1px solid #7c7c7c;
    border-radius: 16px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 14px;
    padding: 4px 10px;
    text-align: center;
    margin-top: -42px;
    position: relative;
    z-index: 1;
    margin-bottom: 20px;
  }

  .step-list {
    padding-left: 0;
    margin-bottom: 0;

    li {
      list-style: none;
      display: flex;
      align-items: center;

      &~li {
        margin-top: 10px;
      }

      img {
        width: 150px;
      }

      .text {
        padding-left: 10px;
        flex: 0 0 calc(100% - 160px);

        strong {
          display: block;
          position: relative;
          font-size: 15px;
          font-weight: 900;
          margin-bottom: 12px;

          &:after {
            content: '';
            display: block;
            width: 40px;
            height: 3px;
            background: #000;
            position: absolute;
            bottom: -5px;
            left: 0;
          }
        }

        p {
          font-size: 13px;
          margin-bottom: 0;
        }
      }
    }
  }
}
.giftcard {
  background: #00bdff;
  padding: 28px 24px 12px;

  .title {
    background: #fff;
    border: 1px solid #7c7c7c;
    border-radius: 16px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 17px;
    padding: 4px 10px;
    text-align: center;
    margin-top: -46px;
    position: relative;
    z-index: 1;
    margin-bottom: 22px;
  }

  p {
    font-size: 13px;
    font-weight: 900;
    text-align: center;
    line-height: 1.2;
  }

  .gc-list {
    padding-left: 0;
    margin-bottom: 0;

    li {
      list-style: none;

      &~li {
        margin-top: 20px;
      }

      .item {
        display: block;
        background: #fff;
        border-radius: 12px;
        overflow: hidden;
        text-decoration: none;
        box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.5);

        img {
          width: 100%;
        }

        .text {
          text-align: center;
          color: #505457;
          font-size: 14px;
          text-transform: uppercase;
          padding: 10px 16px;
          line-height: 1.3;
          font-weight: 500;
          position: relative;

          &::before {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background: #0091c3;
            border-radius: 50%;
            position: absolute;
            top: -10px;
            left: -10px;
          }

          &::after {
            content: '';
            display: block;
            width: 20px;
            height: 20px;
            background: #0091c3;
            border-radius: 50%;
            position: absolute;
            top: -10px;
            right: -10px;
          }

          &.text2 {
            strong {
              font-size: 20px;
              font-weight: 500;
              display: block;
            }

            span {
              font-size: 12px;
              display: block;
            }
          }
        }
      }
    }
  }

  .see-detail {
    text-align: center;
    margin-top: 30px;
    font-weight: 700;
    font-size: 17px;

    a {
      color: #000;
      text-decoration: underline;
    }
  }
}
.button-download {
  &#sticky {
    position: sticky;
    padding: 12px 24px;
    height: auto;
    background: #00bdff;
    box-shadow: none;

    .btn-download {
      background: #d20301;
      color: #fff;
      display: block;
      border-radius: 24px;
      font-weight: 500;
      font-size: 20px;
      text-transform: uppercase;
      padding: 4px 12px;
      text-decoration: none;
    }
  }
}

</style>
